import * as React from 'react';

function NotificationsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 21" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.447 17.332c-1.596 0-1.596-2.357 0-2.357 1.293 0 1.369-4.56 1.369-6.157 0-3.8 2.28-6.005 4.865-6.537C7.225 1.216 7.985 0 9.125 0s1.9 1.216 1.444 2.28c2.585.533 4.865 2.737 4.865 6.538 0 1.596.076 6.157 1.369 6.157 1.596 0 1.596 2.357 0 2.357H1.447zM10.493 17.864c.608 1.064-.152 2.433-1.368 2.433-1.216 0-1.977-1.369-1.368-2.433h2.736z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoNotificationsIcon = React.memo(NotificationsIcon);
export default MemoNotificationsIcon;
