import { FC } from 'react';
import { AuthenticatedRoutes, UnauthenticatedRoutes } from 'routes';
import useAuth from './context/Authentication';
import { SidebarContextProvider } from 'pages/Admin/adminContext/Sidebar/SidebarContext';
export type AuthGateProps = {};

export const AuthGate: FC<AuthGateProps> = () => {
  const {
    state: { isLoggedIn },
  } = useAuth();
  

  if (isLoggedIn) {
    return <SidebarContextProvider><AuthenticatedRoutes /> </SidebarContextProvider>;
  }

  return <UnauthenticatedRoutes />;
};
