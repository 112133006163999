import { Text } from 'atoms/Text';
import { ErrorMessage } from 'formik';

interface ErrorProps {
  text: string;
}
export const Error = ({ text }: ErrorProps) => {
  return (
    <Text
      as="span"
      variant="small"
      color="red.100"
      position="absolute"
      left="0px"
    >
      {text}
    </Text>
  );
};
export const FormikError = ({ name }: { name: string }) => {
  return <ErrorMessage name={name}>{(error) => <Error text={error} />}</ErrorMessage>;
};
