import { FC } from "react";
import styled from "styled-components";
import css from "@styled-system/css";

import { Box } from "atoms/Box";
import { Text } from "atoms/Text";
import { Error } from "atoms/Error";
import { UserIcon } from 'assets/icons/UserIcon';
import {LockIcon} from 'assets/icons/LockIcon';

import MemoMailIcon from 'assets/icons/MailIcon';
import { Warning } from "atoms/Warning";
import { Input, InputBaseProps } from "atoms/Input";
import { Merge } from "typings/utils";


export const InputFieldBase = styled(Box)<InputBaseProps>`
  position: relative;
  width: 100%;
  display: grid;
  align-items: center;
  margin: 0 0 12px 0;
  gap: 1px;
  label {
    font-size: 1rem;
    ${css({
      span: {
        position: "absolute",
        top: "50%",
        left: "12px",
        transform: "translateY(-50%)",
        transition: "0.2s all",
      },
      input: {
        "::placeholder": {
          opacity: 0,
          visibility: "hidden"
        },
        "&:focus + span, &:not(:placeholder-shown) + span": {
          display: "none",
        },
        ":disabled":{
          color:'#afafaf',
          background:'#d9d9d9'
        }
      },
    })}
  }
`;

interface InputFProps {
  label?: string;
  error?: string;
  warning?: string;
  labelHide?: boolean;
  labelTop?: boolean;
  iconType: string;
  borderRequired?:boolean;
}

export type InputFieldProps = Merge<InputFProps, InputBaseProps>;

export const InputField: FC<InputFieldProps> = ({
  label,
  error,
  warning,
  iconType,
  labelTop,
  labelHide,
  borderRequired=false,
  ...props
}) => {
  return (

    <InputFieldBase>
      {labelTop === false &&(
      
        <Text as="label" color="rgba(0,0,0,0.3)" fontSize={{ xs: 7 }}>
          {borderRequired === false &&(
            <>
          <Input
          iconType={iconType}
            placeholder={label}
            borderColor={error ? "red.100" : undefined}
            {...props}
            color="#000000"
          />
          <Text as="span" color={error ? "red.100" : undefined} width="100%" fontSize="0.975rem">
          {iconType === "user" &&(<UserIcon color={error? `#ff4d4f`:`rgba(0,0,0,0.85)`}/>)} 
          {iconType === "lock" &&(<LockIcon color={error? `#ff4d4f`:`#D9D9D9`}/>)}
          {iconType === "mail" &&(<MemoMailIcon/>)}
          &nbsp;{label}
          </Text>
          </>
          )}
          {
            borderRequired &&(
              <>
              <Input
              iconType={iconType}
                placeholder={label}
                {...props}
                color="#000000"
              />
              <Text as="span" width="100%" fontSize="0.975rem">
              {iconType === "user" &&(<UserIcon color={error? `#ff4d4f`:`rgba(0,0,0,0.85)`}/>)} 
              {iconType === "lock" &&(<LockIcon color={error? `#ff4d4f`:`#D9D9D9`}/>)}
              {iconType === "mail" &&(<MemoMailIcon/>)}
              &nbsp;{label}
              </Text>
              </>
            )
          }
         
        </Text>
      )}

      <Text mt="8px" fontSize="0.699rem" mb="5px">
        {error && <Error text={error} />}
      </Text>
      {warning && <Warning text={warning} />}
    </InputFieldBase>
  );
};
