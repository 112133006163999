import {
  forwardRef,
  useRef,
  DetailedHTMLProps,
  InputHTMLAttributes,
  useMemo,
  useState,
  useCallback,
} from 'react';
import styled from 'styled-components';
import MemoEyeOpenIcon from 'assets/icons/EyeOpenIcon';
import MemoEyeCloseIcon from 'assets/icons/EyeCloseIcon';
import {CrossIcon} from 'assets/icons/CrossIcon';
import mergeRefs from 'utils/mergeRefs';
import { Merge } from 'typings/utils';
import {
  space,
  layout,
  color,
  position,
  border,
  shadow,
  typography,
  variant,
  SpaceProps,
  ColorProps,
  LayoutProps,
  PositionProps,
  TypographyProps,
  BorderProps,
  ShadowProps,
} from 'styled-system';

import { Flex } from 'atoms/Flex';
import { Text } from 'atoms/Text';

type Variant = 'primary' | 'secondary' | 'error';

interface InputBProps
  extends SpaceProps,
    ColorProps,
    LayoutProps,
    PositionProps,
    TypographyProps,
    BorderProps,
    ShadowProps {
  variant?: Variant;
  maxLength?: any;
  value?: any;
  autoFocus?: boolean;
  defaultValue?: string;
  height?: any;
  width?: any;
  size?: any;
  color?: string;
  placeholder?: string;
  iconType?:string
}

export type InputBaseProps = Merge<
  Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'ref'>,
  InputBProps
>;

export const InputBase = styled.input<Omit<InputBaseProps, 'placeholder'>>`
  width: 100%;
  font-weight: 400;
  ${variant({
    variants: {
      primary: {
        p: 2,
        border: '1px solid',
        borderColor: 'gray.400',
        outline: 'none',
        '&:focus, &:active': {
          bg: 'white.0',
        },
        '&::placeholder': {
          color: 'gray.600',
        },
      },
      secondary: {
        display: 'block',
        border: 'none',
        borderBottom: '1px solid',
        borderColor: 'gray.500',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
      },
      error: {
        display: 'block',
        color: 'red.500',
        border: 'none',
        borderBottom: '1px solid',
        borderColor: 'gray.500',
      },
    },
  })};

  ${space}
  ${layout}
  ${color}
  ${position}
  ${border}
  ${shadow}
  ${typography}
`;

export const Input = forwardRef<HTMLInputElement, InputBaseProps>(
  ({ iconType, autoFocus, variant: v = 'primary', type, onChange, ...props}, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isOpen, setOpen] = useState(false);
    const [toggleVisible, setToggleVisible] = useState(true);
  

    const toggle = useCallback(() => setOpen((prev) => !prev), []);

    const isPassword = useMemo(() => type === 'password', [type]);

    
    

    const onChangeHandler = useCallback(
      (e) => {
        if (typeof onChange === 'function') {
          onChange(e);
        }
        setToggleVisible(e?.currentTarget?.value?.length > 0);
      },
      [onChange],
    );

    return (
      <>
        {isPassword && toggleVisible && (
          <Flex
            onClick={toggle}
            position="absolute"
            top={0}
            right={0}
            cursor="pointer"
            alignItems="center"
            height="100%"
            width="2.5rem"
            justifyContent="center"
          >
            <Text textDecoration="underline" firstLetterCapital paddingTop="3px">
              {isOpen ?  <MemoEyeCloseIcon/>:<MemoEyeOpenIcon/>}
            </Text>
          </Flex>
        )}
        {iconType === "cross" &&(
          <Flex
            onClick={toggle}
            position="absolute"
            top={0}
            right={0}
            cursor="pointer"
            alignItems="center"
            height="100%"
            width="2.5rem"
            justifyContent="center"
          >
            <Text textDecoration="underline" firstLetterCapital paddingTop="3px">
              <CrossIcon />
            </Text>
          </Flex>
        )}
      
         
        <InputBase
          type={isPassword && isOpen ? 'text' : type}
          variant={v}
          ref={mergeRefs(ref, inputRef)}
          onChange={(e)=>onChangeHandler(e)}
          {...props}
        />
      </>
    );
  },
);
