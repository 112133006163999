import { FC } from 'react';

import ReactModal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    width: '500px',
    maxWidth: '100vw',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 0,
    boxShadow: '0px 0px 15px rgba(196, 196, 196, 0.3)',
    padding:0
  },
  overlay: {
    background: 'rgba(254, 249, 230, 0.12)',
    zIndex: 100,
  },
};

ReactModal.setAppElement('#root');

export type ModalProps = { onClose?: () => void } & ReactModal.Props;

export const Modal: FC<ModalProps> = ({ isOpen, onClose, ...rest }) => (
  <ReactModal isOpen={isOpen} onRequestClose={onClose} style={customStyles} {...rest} />
);
