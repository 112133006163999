import { useCallback, useReducer } from 'react';
import { createContainer, createReducer, createAction } from 'utils/context';
import { removeCookie, saveCookie } from 'utils/cookie';
import { api } from 'utils/api/api';
// import { sha512 } from 'js-sha512';
import { toast } from 'react-toastify';
import { hydrate, persist } from 'utils/persist';
import { ACCESS_TOKEN } from 'utils/api/getTokens';
// import {useHistory} from "react-router-dom";


declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}

export type AuthState = {
  authenticating: boolean;
  isLoggedIn: boolean;
  user?: any;
  userId?: any;
  notifications: any;
};

const initialState: AuthState = {
  authenticating: true,
  isLoggedIn: true,
  notifications: {
    list: [],
    unReadCount: 0,
  },
};

const actions = {
  adminLogin: createAction('LOGIN'),
  resetAuth: createAction('RESET')
};

const authReducer = createReducer<AuthState>({
  [`${actions.adminLogin}`]: (state, { payload }) => ({
    ...state,
    user: payload,
    isLoggedIn: true,
  }),
  [`${actions.resetAuth}`]: (state) => ({
    ...state,
    user: undefined,
    isLoggedIn: false,
  }),
});

export const {
  useContext: useAuth,
  Context: AuthContext,
  Provider: AuthProvider,
  TestProvider: TestAuthProvider,
} = createContainer(() => {
  const [state, dispatch] = useReducer(authReducer, {
    ...initialState,
    user: hydrate('user', 'localStorage') || undefined,
    isLoggedIn: !!localStorage.getItem('user'),
  });

  //Authentication ------------------------------------------------------------------------------------------------------------------
  //login
  const login = useCallback(async (values, callback?: () => void) => {
    // const password = sha512(values.password);
    let valueCopy = { Email: values?.user, Password: values?.password };
    localStorage.setItem('key', '1');
    console.log(valueCopy);
    //const password = values.password;
    try {
      const { data } = await api('/api/Admin/Login', {
        method: 'post',
        data: valueCopy,
      });


      if (data?.status === "Success") {
        if(data?.data?.userDetails?.role === "Admin" || data?.data?.userDetails?.role === "Manager"){
        if (data?.data?.forcePasswordReset === false && data?.data?.userDetails?.isVerified === true) {
          persist('user', data?.data, 'localStorage');
          saveCookie(ACCESS_TOKEN, data?.data?.access_token);
          dispatch(actions.adminLogin(data.data));
          return data?.data; 
        }
        else {
            return data?.data;
        }}else{
          toast.error("You are not authorized to login to admin portal.")
        }
      
      } 
      else {
        return data;
      }
    }
    catch (error: any) {
      console.log(error.message);

    }
  }, []);



  //logout
  const logout = useCallback(async () => {
  
    dispatch(actions.resetAuth());
    localStorage.removeItem('user');
    localStorage.removeItem('key');
    localStorage.removeItem('img');
    localStorage.removeItem('imageType');
    localStorage.removeItem('tempImg');
    localStorage.removeItem('accessKeys');
    localStorage.clear();
    removeCookie(ACCESS_TOKEN);
  }, []);



  //forget Password
  const forgotPassword = useCallback(
    async (values, callback?: () => void) => {
      try {
        const {data} = await api('/api/Admin/ForgotPassword', {
          method: 'post',
          data: values,
        });

      if(data.status === "Success"){

        if (typeof callback === 'function') {
          callback();
        }
        toast.success('Otp has been sent successfully to your email');
      }else{
        toast.error(data.message);
      }
      } catch (error: any) {
        toast.error(error.message);
      }
    },
    []
  );

  //verify Otp
  const verifyOtp = useCallback(
    async (values, callback?: (hash?: any) => void) => {
      try {
        const { data } = await api('/api/Admin/VerifyForgotPassword', {
          method: 'post',
          data: values,
        });

        if (typeof callback === 'function') {
          if (data?.status === "Failed") {
            toast.error(data?.message);
          } else {
            toast.success('Verification Code has been Successfully verified');
            callback(data?.data?.user?.resetHash);
          }
        }

      } catch (error: any) {
        toast.error(error.message);
      }
    },
    []
  );

  ////verify Otp First Time Sign In
  const verifyOtpFirst = useCallback(
    async (values, callback?: (hash?: any) => void) => {
      try {
        const { data } = await api('/api/Admin/VerifySignup', {
          method: 'post',
          data: values,
        });

        if (typeof callback === 'function') {
          if (data?.status === "Failed") {
            toast.error(data?.message);
          } else {
            toast.success('Verification Code has been Successfully verified');
            callback(data?.data?.user?.resetHash);
          }
        }

      } catch (error: any) {
        toast.error(error.message);
      }
    },
    []
  );

  //resend otp
  const resendOtp = useCallback(
    async (values, callback?: () => void) => {
      let url = `/api/Admin/ResendForgotPassword`;
      try {
        const data = await api(url, {
          method: 'post',
          data: { Email: values }
        });
        console.log(data);
        if (typeof callback === 'function') {
          callback();
        }
        toast.success('Resend Verification Code Successfully');
      } catch (error: any) {
        toast.error(error.message);
      }
    },
    []
  );

   //resend otp
   const resendOtpFirst = useCallback(
    async (values, callback?: () => void) => {
      let url = `/api/Admin/ResendSignUp`;
      try {
        const data = await api(url, {
          method: 'post',
          data: { Email: values }
        });
        console.log(data);
        if (typeof callback === 'function') {
          callback();
        }
        toast.success('Resend Verification Code Successfully');
      } catch (error: any) {
        toast.error(error.message);
      }
    },
    []
  );

  //reset Password
  const resetPassword = useCallback(async (values, callback?: () => void) => {

    //   const password = sha512(values.values.password);
    //  // const password = values.values.password;
    //   const rawCode = values.data;
    try {
      const data = await api(`/api/Admin/ResetPassword`, {
        method: 'post',
        data: values,
      });
      console.log(data);
      if (typeof callback === 'function') {
        callback();
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  }, []);



  //End Authentication ------------------------------------------------------------------------------------------------------------------

  //User --------------------------------------------------------------------------------------------------------------------------------

  const addUser = useCallback(async (values, callback?: () => void) => {

    try {
      const { data } = await api(`/api/Admin/CreateUsers`, {
        method: 'post',
        data: values,
      });

      if (typeof callback === 'function') {
        if (data?.status === "Failed") {
          toast.error(data?.message);
        } else {
          toast.success(data.message);
          callback();
        }
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  }, []);


  const getAllUsers = useCallback(async (value) => {
    let url = `/api/Admin/GetUsers`;
    let values = { PageNumber: value?.page, PageSize: value?.limit, Role: value?.Role,Search:value?.Search};
    if (value?.Search === "" || value?.Search === null) {
      delete values?.Search;
    } else {
      values.Search = value?.Search
    }
    
    try {
      const { data } = await api(url, {
        method: 'post',
        data: values
      });

      console.log(data);
      return data?.data;

    } catch (error: any) {
      toast.error(error.message);
    }
  },
    []
  );

  const updateUserStatus = useCallback(
    async (values, callback?: () => void) => {
      let url = `/api/Admin/UpdateUserStatus`;
      try {
        const data = await api(url, {
          method: 'put',
          data: values
        });

        console.log(data);

        if (typeof callback === 'function') {
          callback();
        }
        toast.success(`User Status updated`);
      } catch (error: any) {
        toast.error(error.message);
      }
    },
    []
  );

  const updateUsers = useCallback(
    async (values, callback?: () => void) => {
      let url = `/api/Admin/UpdateUserDetails`;
      try {
        const data = await api(url, {
          method: 'put',
          data: values
        });

        console.log(data);

        if (typeof callback === 'function') {
          callback();
        }
        toast.success(`User Updated Successfully`);
      } catch (error: any) {
        toast.error(error.message);
      }
    },
    []
  );

  const updateDriver = useCallback(
    async (values, callback?: () => void) => {
      let url = `/api/Admin/UpdateDriverDetails`;
      try {
        const data = await api(url, {
          method: 'put',
          data: values
        });

        console.log(data);
        if (typeof callback === 'function') {
          callback();
        }
        toast.success(`Driver Updated Successfully`);
      } catch (error: any) {
        toast.error(error.message);
      }
    },
    []
  );

  const getDrivers = useCallback(async (values) => {
    let value = { PageNumber: values?.page, PageSize: values?.limit,Search:values?.Search,isExternal:values?.isExternal};
    if (value?.Search === "" || value?.Search === null) {
      delete value?.Search;
    } else {
      value.Search = values?.Search
    }

   
    try {
      const { data } = await api(`/api/Admin/GetDrivers`, {
        method: 'post',
        data: value,
      });
      return data;

    } catch (error: any) {
      toast.error(error.message);
    }
  }, []);

  const getDrivers1 = useCallback(async () => {
    try {
      const { data } = await api(`/api/Admin/GetDriversPlain`, {
        method: 'post',
        data:{}
      });
      return data;

    } catch (error: any) {
      toast.error(error.message);
    }
  }, []);

  const getEmail = useCallback(async (values) => {
    try {
      const { data } = await api(`/api/Admin/GetLinkedEmailContacts`, {
        method: 'post',
        data: values,
      });
      return data;

    } catch (error: any) {
      toast.error(error.message);
    }
  }, []);


  const uploadCsvFile = useCallback(async (values) => {
    try {
      const formData = new FormData();
      formData.append("file",values);
      const { data } = await api(`/api/Admin/DriverManagerRelationShipUpload`, {
        method: 'post',
        data: formData,
      });
    
      toast.success('Excel uploaded');
      return data;

    } catch (error: any) {
      toast.error(error.message);
    }
  }, []);

  //End User ----------------------------------------------------------------------------------------------------------------------------

  //Inspection -------------------------------------------------------------------------------------------------------------------------

  const getAllInspection = useCallback(async (value) => {
    console.log(value,"yyyyyyyyyyyyy");
    let sort= value?.sort.substring(0,value?.sort?.indexOf('|'));
    if(sort ==="truckNumber"){
      sort ="TruckNumber";
    }else if(sort === "carrierName"){
      sort = "CarrierName";
    }
    else if(sort === "driverName"){
      sort = "DriverName";
    }else if(sort === "trailerNumber"){
      sort = "TrailerNumber";
    }
    else if(sort === "driverReviewSignDate"){
      sort = "ResolutionDate";
    }
    else if(sort === "roNumber"){
      sort = "RoNumber";
    }
    else if(sort === "inspectionStatusId"){
      sort = "Status";
    }
    else{
      sort =0;
    }

    let key =value?.sort.substring(value?.sort?.indexOf('|')+1);
    if(key===""){
      key =0;
    }
    
    let url = `/api/Admin/GetInspectionList`;
    let values = { InspectionStatusIds: [1, 2, 3, 4, 5, 6, 7, 8], PageNumber: value?.page, PageSize: value?.limit, FromDate: value?.FromDate, ToDate: value?.ToDate, DriverName: value?.DriverName, InspectionDate: value?.InspectionDate, Search: value?.Search,DateOfCompletionFromDate:value?.ResolutionDate[0],DateOfCompletionToDate:value?.ResolutionDate[1],Key:key,Sort:sort };
    if (value?.DriverName === 0 || value?.DriverName === null || value?.DriverName === undefined) {
      delete values?.DriverName;
    } else {
      values.DriverName = value?.DriverName
    }
    if (!value.InspectionStatusIds.includes(0)) {
      values.InspectionStatusIds = value?.InspectionStatusIds
    }
    if (value?.InspectionDate === 0 || value?.InspectionDate === null) {
      delete values?.InspectionDate;
    } else {
      values.InspectionDate = value?.InspectionDate
    }
    if (value?.ResolutionDate === 0 || value?.ResolutionDate === "Invalid date") {
      delete values?.DateOfCompletionFromDate;
      delete values?.DateOfCompletionToDate;
    } else {
      values.DateOfCompletionFromDate = `${value?.ResolutionDate[0].format('MM/DD/YYYY')} 04:00:00`
      values.DateOfCompletionToDate = `${value?.ResolutionDate[1].add(1,"day").format('MM/DD/YYYY')} 03:59:00`
    }
    if (value?.Search === "" || value?.Search === null) {
      delete values?.Search;
    } else {
      values.Search = value?.Search
    }

    if(values?.Key ===0){
      delete values?.Key
    }else{
      values.Key = key
    }

    if(values?.Sort === 0){
      delete values?.Sort
    }else{
      values.Sort = sort;
    }
    
    try {
      const { data } = await api(url, {
        method: 'post',
        data: values
      });

      return data?.data;

    } catch (error: any) {
      toast.error(error.message);
    }
  },
    []
  );

  const getOneInspection = useCallback(async (values) => {
    let url = `/api/Admin/GetInspectionDetails`;
    console.log(values);
    try {
      const { data } = await api(url, {
        method: 'post',
        data: values
      });

      return data?.data;

    } catch (error: any) {
      toast.error(error.message);
    }
  },
    []
  );

  const DownloadInspectionReport = useCallback(async (values, callback?: () => void) => {
    let url = `/api/Admin/DownloadInspectionPdf`;

    try {
      const { data } = await api(url, {
        method: 'post',
        data: values,
        responseType: 'blob'
      });

      if (typeof callback === 'function') {
        callback();
      }

      downloadFile(data, 'report');

    } catch (error: any) {
      toast.error(error.message);
    }
  },
    []
  );

  const downloadFile = (data: any, filename: any) => {
    var fileURL = URL.createObjectURL(data);
    console.log(fileURL);
    window.open(fileURL);
  };


  const updateInspectionRo = useCallback(
    async (values, callback?: () => void) => {
      let url = `/api/Admin/updateInspection`;
      try {
        const data = await api(url, {
          method: 'put',
          data: values
        });

        console.log(data);
        if (typeof callback === 'function') {
          callback();
        }
        toast.success(`Resolve Updated Successfully`);
      } catch (error: any) {
        toast.error(error.message);
      }
    },
    []
  );


  const GetAccessKeys = useCallback(async () => {
    let url = `/api/Admin/AWS/Session/Credentials`;

    try {
      const { data } = await api(url, {
        method: 'post',
      });
      console.log(data);
      localStorage.setItem('accessKeys', JSON.stringify(data));
      return data;
    } catch (error: any) {
      toast.error(error.message);
    }
  },
    []
  );



  //End Inspection ---------------------------------------------------------------------------------------------------------------------
  return {
    state,
    actions: {
      login,
      logout,
      forgotPassword,
      verifyOtp,
      resetPassword,
      resendOtp,
      getAllUsers,
      updateUserStatus,
      addUser,
      updateUsers,
      getDrivers,
      updateDriver,
      verifyOtpFirst,
      getEmail,
      getAllInspection,
      getOneInspection,
      DownloadInspectionReport,
      updateInspectionRo,
      GetAccessKeys,
      resendOtpFirst,
      getDrivers1,
      uploadCsvFile
    },
  };
});

export default useAuth;
