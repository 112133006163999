import {FC} from 'react'
import { Box, Grid } from 'atoms';
import {Switch,Route,Redirect} from 'react-router-dom';
import AdminLogin from '../Login';
import ForgotPassword from '../ForgotPassword';
import Otp from '../Otp';
import ResetPassword from '../ResetPassword';
import { urlQueryToObj } from 'utils/buildQuery';
import {base64,toJSON} from 'utils/helpers';
import { useLocation } from 'react-router';


export type AuthContainerProps ={};

export const AuthContainer: FC<AuthContainerProps> =()=> {
  const location = useLocation();
    return (
        <Grid
        height="100vh"
      >
        <Box flex="1">
          <Grid
            mx="auto"
            minWidth="500px"
            width="max-content"
            justifyContent="center"
            alignContent="center"
            height="100vh"
          >
            <Switch>
            <Route exact path="/login" component={AdminLogin} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path='/otp/' render={(props)=>{
              let {code}:any =urlQueryToObj(location.search);
              if(code){
                try{
                  let parsedCode:any = toJSON(base64.decode(code));
                  return(
                    <Otp type="otp" rawCode={code} inviteInfo={parsedCode} {...props}/>
                  )
                }catch(e){
                  return (
                    <Redirect
                      push
                      to="/page404"
                    />
                  );
                }
              }
              //404
									return <Redirect push to="/page404" />;
            }}/>
            <Route exact path='/reset-password' component={ResetPassword}/>
            <Redirect to="/login" />
          </Switch>
       </Grid>
       </Box>
       </Grid>
    )
}

