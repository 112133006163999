export interface LockIconProps {
    color:string
  }
  
  export const LockIcon = (props: LockIconProps) => (
    <svg width="14" height="16" viewBox="0 -1 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.7163 7.14802H11.502V3.14802C11.502 1.88552 10.4788 0.862305 9.21631 0.862305H4.78774C3.52524 0.862305 2.50202 1.88552 2.50202 3.14802V7.14802H1.28774C0.971666 7.14802 0.716309 7.40338 0.716309 7.71945V14.5766C0.716309 14.8927 0.971666 15.148 1.28774 15.148H12.7163C13.0324 15.148 13.2877 14.8927 13.2877 14.5766V7.71945C13.2877 7.40338 13.0324 7.14802 12.7163 7.14802ZM3.78774 3.14802C3.78774 2.59623 4.23595 2.14802 4.78774 2.14802H9.21631C9.7681 2.14802 10.2163 2.59623 10.2163 3.14802V7.14802H3.78774V3.14802ZM12.002 13.8623H2.00202V8.43373H12.002V13.8623ZM6.50202 11.3802V12.3266C6.50202 12.4052 6.56631 12.4694 6.64488 12.4694H7.35917C7.43774 12.4694 7.50202 12.4052 7.50202 12.3266V11.3802C7.64943 11.2743 7.75945 11.1245 7.81626 10.9521C7.87306 10.7798 7.87372 10.5939 7.81813 10.4211C7.76254 10.2484 7.65358 10.0977 7.50692 9.99087C7.36027 9.884 7.18349 9.82642 7.00202 9.82642C6.82056 9.82642 6.64378 9.884 6.49713 9.99087C6.35047 10.0977 6.24151 10.2484 6.18592 10.4211C6.13033 10.5939 6.13099 10.7798 6.18779 10.9521C6.2446 11.1245 6.35462 11.2743 6.50202 11.3802V11.3802Z" fill={props.color}/>
    </svg>
    
  );
  