import { FC } from "react";
import { Drawer } from "antd";

export type DrawerProps = {
  onClose?: () => void;
  visible: boolean;
  title: string;
  width: number;
  className?:string
};

export const FormDrawer: FC<DrawerProps> = ({
  visible,
  onClose,
  children,
  width,
  title,
  className
}) => (
  <Drawer
    visible={visible}
    onClose={onClose}
    destroyOnClose={true}
    closable={true}
    width={width}
    title={title}
    maskClosable={true}
    className={className}
  >
    {children}
  </Drawer>
);
