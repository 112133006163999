const breakpoints: any = ['30em', '45em', '64em', '80em', '90em', '100em'];

const [sm, md, xm, lg, ml, xl] = breakpoints;
breakpoints.sm = sm;
breakpoints.md = md;
breakpoints.xm = xm;
breakpoints.lg = lg;
breakpoints.ml = ml;
breakpoints.xl = xl;

export const fontSets = [
    {
        name: 'heading',
        fontSize: '1.5rem',
        lineHeight: '2rem',
        fontWeight: '500',
      },
      {
        name: 'title',
        fontSize: '1rem',
        lineHeight: '2rem',
        fontWeight: '500',
      },
      {
        name: 'field',
        fontSize: '0.875rem',
        lineHeight: '1.375rem',
        fontWeight: 'normal',
      },
]

const space = [
    0,
    0.2,
    0.4,
    0.6,
    0.8,
    1,
    1.2,
    1.4,
    1.6,
    1.8,
    2,
    2.5,
    3,
    3.5,
    4,
    5,
    6,
    8,
    10,
    12,
    16,
    20,
  ].map((s) => `${s}rem`);

  const fontMaps = fontSets.reduce(
    (fontMap, { name,fontSize, lineHeight, fontWeight }, index) => {
      const fm = fontMap;
      fm.fontSizes[index] = fontSize;
      fm.fontSizes[name] = fontSize;
      fm.fontWeights[index] = fontWeight;
      fm.fontWeights[name] = fontWeight;
      fm.lineHeights[name] = lineHeight;
      return fm;
    },
    {
      fontFamily: {
        primary: 'Roboto-Regular',
        bold: 'Roboto-Bold',
        light: 'Roboto-Light',
        extraLight: 'Roboto-ExtraLight',
        medium: 'Roboto-Medium',
        semibold: 'Roboto-SemiBold',
      },
      fontSizes: {},
      fontWeights: {
        medium: 500,
      },
      lineHeights: {},
    },
  );
  
  const colorSets = [
    {
      name: 'primary',
      colors: [
        { name: 50, hex: 'orange' },
      ],
    },
    {
      name: 'blue',
      alias: 'info',
      colors: [
        { name: 50, hex: '#1890FF' },
      ],
    },
    {
      name: 'gray',
      colors: [
        { name: 50, hex: '#fafafa' },
        { name: 100, hex: '#262626' },
        { name: 200, hex: '#333333' },
        { name: 300, hex: 'rgba(0, 0, 0, 0.85)' },
        { name: 400, hex: '#D9D9D9' },
        { name: 500, hex: '#9e9e9e' },
        { name: 600, hex: '#717171rgba(0, 0, 0, 0.25)' },
        { name: 700, hex: '#616161' },
        { name: 800, hex: '#424242' },
        { name: 900, hex: '#222222' },
      ],
    },
    {
      name: 'red',
      alias: 'danger',
      colors: [
        { name: 50, hex: '#FF0000' },
        {name: 100, hex:'#ff4d4f'}
      ],
    },
  
    {
      name: 'green',
      alias: 'success',
      colors: [
        { name: 50, hex: '#e8f5e9' },
        { name: 100, hex: '#c8e6c9' },
        { name: 200, hex: '#a5d6a7' },
        { name: 300, hex: '#81c784' },
        { name: 400, hex: '#66bb6a' },
        { name: 500, hex: '#4caf50' },
        { name: 600, hex: '#43a047' },
        { name: 700, hex: '#388e3c' },
        { name: 800, hex: '#2e7d32' },
        { name: 900, hex: '#1b5e20' },
      ],
    },
    {
      name: 'bluegray',
      colors: [
        { name: 50, hex: '#eceff1' },
        { name: 100, hex: '#cfd8dc' },
        { name: 200, hex: '#b0bec5' },
        { name: 300, hex: '#90a4ae' },
        { name: 400, hex: '#78909c' },
        { name: 500, hex: '#D9D9D9' },
        { name: 600, hex: '#546e7a' },
        { name: 700, hex: '#46535B' },
        { name: 800, hex: '#37474f' },
        { name: 900, hex: '#263238' },
      ],
    },
  ];
  const colors = colorSets.reduce(
    (colorMap, { name, alias = name, colors: colorSet }) => {
      const color = {};
      const cm = colorMap;
      for (let colorIndex = 0; colorIndex < colorSet.length; colorIndex++) {
        const { name: colorName, hex } = colorSet[colorIndex];
        color[colorIndex] = hex;
        color[colorName] = hex;
      }
      cm[name] = color;
      cm[alias] = color;
      return cm;
    },
    {
      // ...defaultTheme.colors,
      white: '#FFFFFF',
      'white.0': '#FFFFFF',
      black: '#000000',
      'black.0': '#000000',
    },
  );
  
  // #endregion
  const radii = {
    // ...defaultTheme.radii,
    small: '0.125rem',
    normal: '0.1875rem',
    large: '0.375rem',
    full: '10rem',
    square: 0,
  };
  const zIndices = {
    // ...defaultTheme.zIndices,
    modal: 2000,
    tooltip: 5000,
    toast: 7000,
  };
  
  const shadows = [
    { name: 'none', shadow: undefined },
    { name: 'sm', shadow: '0 .075rem .15rem rgba(0,0,0,.15)' },
    { name: 'xl', shadow: '0 0 1rem rgba(0,0,0,.15)' },
  ].reduce(
    (shadowSet, { name, shadow }, index) => {
      const s = shadowSet;
      s[name] = shadow;
      s[index] = shadow;
      return s;
    },
    // { ...defaultTheme.shadows },
  );
  
  export const theme = {
    // ...defaultTheme,
    breakpoints,
    radii,
    colors,
    space,
    zIndices,
    shadows,
    ...fontMaps,
  };
  
