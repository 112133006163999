import { FC } from 'react';
import * as yup from 'yup';
import { Form, Formik,FormikHelpers } from 'formik';
import StyledNavLink from 'atoms/StyledNavLink';
import { Box, Button, Flex } from 'atoms';
import { FormInput } from 'molecules/FormInput';
// import { Input } from 'antd';
// import { UserOutlined } from '@ant-design/icons';
import { useFormSubmitWithLoading } from 'hooks/useFormSubmitWithLoading';

const MIN = 8;

const LoginValidationSchema = yup.object().shape({
  user: yup.string().email('Invalid Email').required('Email is Required'),
  password: yup.string().required('Password is Required')
  .min(MIN, `Minimum ${MIN} characters needed`)
  .matches(/^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,})$/,
    'Must Contain One Uppercase, One Lowercase, One Number'
  ),
});

type LoginFormValuesType = {
  user: string;
  password: string;
};
export type LoginFormProps = {
  onSubmit?: (
    values?: LoginFormValuesType,
    formikHelpers?: FormikHelpers<LoginFormValuesType>
  ) => void | Promise<void>;
};


export const LoginForm: FC<LoginFormProps> = ({ onSubmit }) => {
  const { onSubmitHandler, loading } = useFormSubmitWithLoading(onSubmit);
  return (
    <Formik
      initialValues={{ user: '', password: '' }}
      onSubmit={onSubmitHandler}
      validationSchema={LoginValidationSchema}
    >
      {() => (
        <Form>
          <Box mt="1.688rem" width="23rem">
            {/* <Input name='user' placeholder="Email Address" suffix={<UserOutlined/>}/> */}
            <FormInput label="Email Address" name="user" labelTop={false} iconType="user"/>

            <FormInput label="Password" name="password" type="password" labelTop={false} iconType="lock" />
            {/* <Input.Password placeholder="password" name="password" /> */}
            <Flex justifyContent="flex-end" mb="1.875rem" width="102%">
              {/* <Checkbox>
                <Text fontSize="0.875rem" color="gray.900">Remember me</Text>
              </Checkbox> */}
              <StyledNavLink to="/forgot-password">Forgot Your Password?</StyledNavLink>
            </Flex>
            <Button width="100%" height="40px" fontSize="1rem" loading={loading}>
              Sign In
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
