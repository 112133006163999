import { Box, BoxProps } from "atoms";
import React from "react";
import styled from "styled-components";
import { UploadIcon } from "assets/icons/UploadIcon";

const StyledButton = styled.input`
  z-index: -1;
  position: absolute;
  opacity: 0;
`;

const StyledLabel = styled.label<UploadButtonProps>`
  cursor: pointer;
`;

export interface UploadButtonProps extends BoxProps {
  isMultiple?: boolean;
  onFileSelect?: (files: any) => void;
  accept?: string;
  label?: string;
  title?: string;
  Icon?: any;
}

export const UploadButton: React.FC<UploadButtonProps> = ({
  isMultiple = false,
  title = "+ Upload",
  onFileSelect,
  accept = "image/*,.pdf",
  label = "file-upload",
  Icon,
}: UploadButtonProps) => {
  return (
    <Box textAlign="center" p="2px 10px" width="152px">
        <span style={{top: "4px",position: "absolute",left: "10px"}}>
        <UploadIcon width={12} height={12}/>
        </span>
      <StyledButton
        type="file"
        id={label}
        multiple={isMultiple}
        onChange={(e: any) => {
          onFileSelect && onFileSelect(e.target.files);
        }}
        accept={accept}
      />
      <StyledLabel htmlFor={label}>{!!Icon ? Icon : title}</StyledLabel>
    </Box>
  );
};
