import css from '@styled-system/css';
import { Select as AntSelect, SelectProps as AntSelectProps } from 'antd';
import { InputFieldBase, Text } from 'atoms';
import { Error } from 'atoms/Error';
import { Warning } from 'atoms/Warning';
import styled from 'styled-components';
import { SpaceProps} from 'styled-system';
const { Option } = AntSelect;

export type OptionType = {
	label: string;
	value: string | number;
};

export interface SelectProps
	extends AntSelectProps<string | number>,
		SpaceProps {
	options?: Array<OptionType>;
	label?: string;
	error?: string;
	warning?: string;
	labelHide?: boolean;
	customStyles?: string;
	minHeight:string;
}

const StyledSelect = styled(AntSelect)<SelectProps & any>`
	.ant-select-selector {
		background-color: ${(props) =>
			props.error ? '#FFF1F1 !important' : '#FFF !important'};
		border-color: ${(props) =>
			props.error ? '#9B3634 !important' : '#D9D9D9 !important'};
	}
	${css({
		width: '100%',
		'.ant-select-selector': {
			minHeight: '40px !important',
			borderRadius: '2px !important',
			border: '1px solid',
		},
		'&:hover': {
			borderColor: '#dee4e5 !important',
		},
		'.ant-select-selection-item': {
			fontSize: '1rem',
			fontWeight: '400',
			padding: '0.3rem 1.938rem 0.3rem 0.1rem !important',
			height:"30px"
		},
		'.ant-select-item-option-active': {},
		'.ant-select-item-option-selected': {
			backgroundColor: `#FCFCFC !important`,
		},
		'.ant-select-selection-item-content':{
			fontSize:'10px'
		},
		'.ant-select-selection-placeholder':{
			paddingTop:'8px !important'
		},
		'.ant-select-arrow':{
			height:"4px",
			width:"5.94px",
			fontSize:"7px",
			paddingTop:"2px"
		},
	})}
`;

export const Select: React.FC<SelectProps> = ({
	options = [{ label: 'Name', value: 'value' }],
	label,
	error,
	warning,
	customStyles,
	minHeight,
	...props
}: SelectProps) => {
	return (
		<InputFieldBase
			width="100%"
			minHeight={minHeight? minHeight:'105px'}
			position="relative"
			className={customStyles}
		>
			<Text
				position="absolute"
				as="label"
				color="rgba(0,0,0,0.3)"
				top="8px"
				left="12px"
				zIndex={2}
			>
				{label}
			</Text>
			<StyledSelect
				{...props}
				error={error}
			>
				{options.map((x: any) => (
					<Option value={`${x.value}`} key={`${x.value}`}>
						{x.label}
					</Option>
				))}
			</StyledSelect>
			{error && <Error text={error} />}
			{warning && <Warning text={warning} />}
		</InputFieldBase>
	);
};
