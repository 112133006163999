import { FC, useState, useCallback,useEffect } from "react";
import * as yup from "yup";
import { Box, Button, Text, Grid } from "atoms";
import { Form, Formik } from "formik";
import OtpInput from "react-otp-input";
import useAuth from "../context/Authentication";
import { useFormSubmitWithLoading } from "hooks/useFormSubmitWithLoading";
import "./otpStyle.css";
import { base64 } from "utils/helpers";
import { useHistory } from "react-router";
import Logo from "assets/loginicon/app_icon.png";

const OtpValidationSchema = yup.object().shape({
  otp:  yup.string()
  .required("Verification code is required")
  .matches(/^[0-9]\d{5}$/, {
    message: "Please enter valid Verification code.",
    excludeEmptyString: false,
  }),
});

export type OtpProps = {
  type: "otp";
  rawCode?: any;
  inviteInfo?: any;
};

export const Otp: FC<OtpProps> = ({ rawCode = null }) => {
  const [time, setTime] = useState(0);
  const [disableResend, setDisableResend]=useState(false);
  const [showResendBtn,setResendBtn]=useState(false);
  const history = useHistory();
  const { user,type } = JSON.parse(base64.decode(rawCode));
  const [otp, setOtp] = useState();
  const [showDidntRecieve, setDidntRecieve] = useState(false);

  console.log(type);

  const {
    actions: { verifyOtp,resendOtp,verifyOtpFirst,resendOtpFirst },
  } = useAuth();

  useEffect(()=>{
    let timeleft = 31;
    let OtpSendTimer = setInterval(function () {
      if (timeleft <= 1) {
        clearInterval(OtpSendTimer);
      }
      let temp = 31 - timeleft;
      console.log(temp);
      timeleft -= 1;
      setTime(timeleft);
      if(timeleft ===0){
      setDidntRecieve(true);
      }
    }, 1000);
  },// eslint-disable-next-line react-hooks/exhaustive-deps
  [])

  const onSubmit = useCallback(
    (values) => {
      const OTP = values.otp;
      const valuesCopy = { OTP:OTP, Email:user };
      if(type !== "FirstLogin"){
      verifyOtp(valuesCopy, (hash) => {
        
        history.push({pathname: '/reset-password',
        state: {
          data: user,
        }},);
      });
    }else{
      verifyOtpFirst(valuesCopy, (hash) => {
        
        history.push({pathname: '/reset-password',
        state: {
          data: user,
        }},);
      });
    }
    },// eslint-disable-next-line react-hooks/exhaustive-deps
    [verifyOtp, history]
  );

  const onResendCode=useCallback(()=>{

    setDisableResend(false);
    setResendBtn(false);
    if(type !== "FirstLogin"){
      resendOtp(user,()=> setResendBtn(false));
    }else{
      resendOtpFirst(user,()=>setResendBtn(false));
    }
  },// eslint-disable-next-line react-hooks/exhaustive-deps
  [resendOtp])

  const { onSubmitHandler, loading } = useFormSubmitWithLoading(onSubmit);

  const handleOtpChange = (e: any, setFieldValue: any) => {
    setOtp(e);
    setFieldValue("otp", e);
  };

  const handleReceive = () => {
    setDisableResend(true);
    setResendBtn(true);
  };

  return (
    <Grid
      border="1px solid #ededed"
      justifyContent="center"
      padding="40px"
      width="488px"
    >
      <Box>
      <Box textAlign="center" mx="auto" width="150px">
          <img src={Logo} width="100%" alt="logo"/>
        </Box>
        <Text
          as="h1"
          fontSize="1.6rem"
          color="primary.50"
          textAlign="center"
          lineHeight="2.6rem"
          mb="1.25rem"
        >
          Enter Verification OTP
        </Text>
        
        <Formik
          initialValues={{ otp: otp }}
          onSubmit={onSubmitHandler}
          validationSchema={OtpValidationSchema}
        >
          {({ errors, values, setFieldValue }) => (
            <Form>
              <Box mt="1.875rem" width="20rem" mx="auto">
                <OtpInput
                  value={otp}
                  onChange={(e: any) => handleOtpChange(e, setFieldValue)}
                  numInputs={6}
                  separator={<span>&nbsp;</span>}
                  containerStyle="otpStyle"
                  isInputNum={false}
                />
                {errors.otp && (
                  <Text mt="5px" ml="10px" fontSize="0.875rem" color="#ff4d4f">
                    {errors.otp}
                  </Text>
                )}
                <Button
                  width="100%"
                  mt="1.875rem"
                  height="40px"
                  fontSize="1rem"
                  loading={loading}
                >
                  Confirm Code
                </Button>
                <Box
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  textAlign="center"
                  mt="30px"
                >
                   <Button variant="transparent" onClick={()=>history.push(`/login`)}>Back to Login</Button>
                  {showDidntRecieve === false &&(
                  <Text color="rgba(38, 38, 38, 0.6)">
                    Request new code in 00:{time}
                  </Text>)}
                  {showDidntRecieve &&(
                   <Button
                    fontSize="1rem"
                    fontWeight="500"
                    variant="transparent"
                    color="#000"
                    onClick={handleReceive}
                    type="button"
                    disabled={disableResend}
                  >
                    Didn't receive it?
                  </Button>
                  )}
                  {showResendBtn &&(
                    <Box display="flex" justifyContent="center">
                    <Button padding="10px" width="120px" textAlign="center" type="button" onClick={onResendCode}>Resend Code</Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Grid>
  );
};
