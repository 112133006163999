import {Grid,Text,Box,Button,Image } from 'atoms';
import Logo404 from 'assets/icons/Logo404.svg';
import {FC} from 'react';
import './page404.css';
import { useHistory } from 'react-router';


export type Page404Props = {};

export const Page404: FC<Page404Props> = () => {
 const history = useHistory();
  return (
    <Grid place-items="center" height="100vh" className="page404" width="100%">
      <Box width="100%" height="100vh" display="flex" justifyContent="center" alignItems="center" className="pageBg">
       <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Image src={Logo404}/>
            <Text as="h1" fontSize="10rem" fontWeight="bolder" textAlign="center" marginTop="-10px" marginBottom="0px">404</Text>
            <Text as="h1" fontSize="2rem">Opps! Page not found</Text>
            <Text >The Page you were looking for doesn't exist.</Text>
            <Button padding="10px" marginTop="10px" borderRadius="3px" onClick={()=>history.go(-2)}>Back to Page</Button>
       </Box>
       </Box>
    </Grid>
  );
};
