import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { theme } from 'utils/styles/theme';
import AuthGate from 'pages/Auth';
import { GlobalStyle } from 'utils/styles';
import { AuthProvider } from 'pages/Auth/context/Authentication';
import { StyledContainer } from 'utils/styles/ToastContainer';
import 'react-toastify/dist/ReactToastify.css';



function App() {
  console.log(process.env.REACT_LOG_ROCKET);
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
      <AuthProvider>
      <GlobalStyle />
        <StyledContainer hideProgressBar autoClose={6000} />
        <Router>
          <AuthGate />
        </Router>
      </AuthProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
