import * as React from 'react';

function SettingsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" {...props}>
      <path
        d="M8.5 10.625a2.125 2.125 0 110-4.25 2.125 2.125 0 010 4.25z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.258 10.625a1.169 1.169 0 01-.234 1.289l-.043.042a1.418 1.418 0 102.005 2.005l.043-.043a1.169 1.169 0 011.289-.233 1.169 1.169 0 01.708 1.07v.12a1.417 1.417 0 002.833 0v-.064a1.169 1.169 0 01.765-1.07 1.169 1.169 0 011.29.234l.042.043a1.417 1.417 0 102.005-2.005l-.043-.043a1.169 1.169 0 01-.234-1.289 1.169 1.169 0 011.07-.708h.12a1.417 1.417 0 100-2.833h-.063a1.169 1.169 0 01-1.07-.765 1.168 1.168 0 01.234-1.29l.042-.042a1.417 1.417 0 10-2.004-2.005l-.043.043a1.17 1.17 0 01-1.289.234h-.057a1.169 1.169 0 01-.708-1.07v-.12a1.417 1.417 0 00-2.833 0v.063a1.169 1.169 0 01-.709 1.07 1.169 1.169 0 01-1.289-.234l-.042-.042a1.417 1.417 0 10-2.005 2.004l.043.043a1.169 1.169 0 01.233 1.289v.057a1.169 1.169 0 01-1.07.708h-.12a1.417 1.417 0 100 2.833h.064a1.17 1.17 0 011.07.709v0z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoSettingsIcon = React.memo(SettingsIcon);
export default MemoSettingsIcon;
