import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

// import ErrorBackground from 'assets/icons/ToastErrorIcon.svg';
// import WarnBackground from 'assets/icons/ToastWarnIcon.svg';
// import InfoBackground from 'assets/icons/ToastInfoIcon.svg';
// import SuccessBackground from 'assets/icons/ToastSuccessIcon.svg';

export const StyledContainer = styled(ToastContainer).attrs({
  // custom props
})`
  .Toastify__toast {
    background-color: white;
    border-left: 6px solid #18aacc;
    border-radius: 7px;
    background-size: 25px;
    background-position: 15px;
    background-repeat: no-repeat;
    padding-left: 1.5rem;
    box-shadow: 0px 0px 22px rgba(226, 226, 226, 0.3);
  }
  .Toastify__toast--info {
    border-left: 6px solid #18aacc;
  }
  .Toastify__toast--error {
    border-left: 6px solid #da1a32;
  }
  .Toastify__toast--warning {
    border-left: 6px solid #f1b75f;
    
  }
  .Toastify__toast--success {
    border-left: 6px solid #00a699;
    
  }
  .Toastify__toast-body {
    color: black;
  }
  button[aria-label='close'] {
    color: black;
    align-self: center;
  }
`;
