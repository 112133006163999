import * as React from 'react';

function MailIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="14"
    height="16"
    viewBox="0 -2 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.0973 2.21411H1.24013C0.924058 2.21411 0.668701 2.46947 0.668701 2.78554V14.2141C0.668701 14.5302 0.924058 14.7855 1.24013 14.7855H16.0973C16.4133 14.7855 16.6687 14.5302 16.6687 14.2141V2.78554C16.6687 2.46947 16.4133 2.21411 16.0973 2.21411ZM15.383 4.19268V13.4998H1.95442V4.19268L1.46156 3.80875L2.16334 2.90697L2.92763 3.50161H14.4116L15.1758 2.90697L15.8776 3.80875L15.383 4.19268ZM14.4116 3.49983L8.6687 7.96411L2.92584 3.49983L2.16156 2.90518L1.45977 3.80697L1.95263 4.1909L8.05263 8.93375C8.22806 9.07004 8.44388 9.14402 8.66602 9.14402C8.88817 9.14402 9.10399 9.07004 9.27942 8.93375L15.383 4.19268L15.8758 3.80875L15.1741 2.90697L14.4116 3.49983Z" fill="black" fill-opacity="0.25"/>
    </svg>
    
  );
}

const MemoMailIcon = React.memo(MailIcon);
export default MemoMailIcon;
