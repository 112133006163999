import { AxiosRequestConfig } from 'axios';
import { ApiInit } from './api.types';
import { getBearerToken, getAccessToken, getUserToken } from './getTokens';
import makeHeaders from './makeHeaders';
// import { loadCookie } from 'utils/cookie';

const generateDefaults = (): ApiInit => {
  let headers = {};
//   let version = { 
//     disabled: false,
//     key: 'x-package-version', 
//     name: 'Package version', 
//     value: 'v0.1.0'
// }

// let packageName = { 
//   disabled: false,
//   key: 'x-package-name', 
//   name: 'Package Name', 
//   value: 'com.wekan.jumpclear.admin'
// }

// let deviceId ={ 
//   disabled: false,
//   key: 'x-device-id', 
//   name: 'Device Id', 
//   value: loadCookie('DeviceId')
// }

// headers={...headers,version,packageName,deviceId};

  const accessToken = getAccessToken();
  const authToken = getBearerToken();
  const userToken = getUserToken();
  if (userToken) {
    headers['access-token'] = userToken;
    // headers['x-package-name']='com.wekan.jumpclear.admin';
  } else if (accessToken) {
    headers['access-token'] = accessToken;
    
  }
  if (accessToken) {
    headers['auth-token'] = authToken;
  }
console.log(headers)
  return {
    method: 'GET',
    headers: {
      // Need to add logic for refresh tokens
      ...headers,
    },
  };
};
const generateApiDefaults = (): AxiosRequestConfig => {
  const headers: Record<string, string> = {};
  const accessToken = getAccessToken();
  if (accessToken) {
    headers.authorization = `Bearer ${accessToken}`;
  }
  return {
    method: 'get',
    headers: {
      ...headers,
    },
  };
};
const noTransformList = ['Blob', 'ArrayBuffer', 'FormData', 'URLSearchParams', 'ReadableStream'];

function shouldStringify(body: any) {
  if (typeof body === 'string') {
    return false;
  }

  if (
    body.constructor &&
    body.constructor.name &&
    noTransformList.some((proto) => body.constructor.name === proto)
  ) {
    return false;
  }

  return true;
}

export default function makeRequestInit(overrides?: ApiInit): RequestInit {
  const init = {
    ...generateDefaults(),
    ...overrides,
  };
  // Convert object to Headers object
  if (init.headers && typeof init.headers === 'object') {
    init.headers = makeHeaders(init.headers as any);
  } else {
    init.headers = undefined;
  }
  // Automatically stringify body
  if (init.body && shouldStringify(init.body)) {
    init.body = JSON.stringify(init.body);
  }
  return init;
}

export const makeRequestInitt = (overrides: AxiosRequestConfig): AxiosRequestConfig => {
  const init = {
    ...generateApiDefaults(),
    ...overrides,
  };
  return init;
};
