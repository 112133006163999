import {
  AuthenticationException,
  PermissionException,
  ServerException,
} from "exceptions";
import axios, { AxiosRequestConfig } from "axios";
import { removeCookie, saveCookie } from "utils/cookie";
import { ACCESS_TOKEN } from "utils/api/getTokens";
import { ApiInit, ApiResult } from "./api.types";
import getResponseContent from "./getResponseContent";
import makeRequestInit, { makeRequestInitt } from "./makeRequestInit";
import {toast} from 'react-toastify';
import { loadCookie } from "utils/cookie";

export interface ApiOptions extends ApiInit {
  keepBaseUri?: boolean;
  sendAccessToken?: boolean;
}

export default async function apiFetch<DataType = any>(
  relativeUri: string,
  options: ApiOptions = {}
) {
 
  try {
    const init = makeRequestInit(options);
    const { keepBaseUri } = options;
    const base = keepBaseUri
      ? process.env.REACT_APP_API_HOST
      : `${process.env.REACT_APP_API_HOST}`;
    const response = await fetch(`${base}${relativeUri}`, init);

    if (response.status >= 400) {
      throw response;
    }

    const result: ApiResult<DataType> = {
      response,
      data: await getResponseContent<DataType>(response),
    };

    return result;
  } catch (response: any) {
    const content = await getResponseContent(response);
    let message;

    if (content && content.message) {
      ({ message } = content);
    }

    if (response.status === 401) {
      if(loadCookie('access_token') !== ''){
        throw new AuthenticationException(message);
      }
    }

    if (response.status === 403) {
      throw new PermissionException(message);
    }

    throw new ServerException(content);
  }
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  //timeout: 60000,
});

axios.defaults.timeout = 600000
export const api = async <DataType = any>(
  relativeUri: string,
  options: AxiosRequestConfig = {}
) => {
  const init = makeRequestInitt(options);
  try {
    const response: DataType = await axiosInstance.request({
      ...init,
      url: relativeUri,
    });
    return response;
  } catch (error: any) {
    console.log(error.response?.data);
    if (error.response && error.response.status === 400) {
      throw new ServerException({
        message: error?.response?.data?.message,
      });
    }
    if (error.response && error.response.status === 500) {
      throw new ServerException({
        message: error?.response?.data?.message,
      });
    }

    // refresh token stuff
    if (
      error.response &&
      error.response.status === 401 &&
      error?.response?.data?.Status === "Unauthorized"
    ) {
      redirectToLogin();
      if(!loadCookie('access_token')){
      toast.error(error?.response?.data?.error?.message);
      }
      const userInfoData = localStorage.getItem("user") as string;
      const userData = JSON.parse(userInfoData);
      const token = userData.user.accessToken;

      const url = `${process.env.REACT_APP_API_HOST}auth/refresh`;
      let status: number;
      fetch(url, {
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          status = response.status;
          return response.json();
        })
        .then((response) => {
          if (status === 200) {
            updateTokens(userData, response);
          } else {
            redirectToLogin();
          }
        })
        .catch((err) => redirectToLogin());
    }
    
    if (
      error.response &&
      error.response.status === 401
    ) {
      console.log(loadCookie('access_token'));
      if(!loadCookie('access_token')){
      toast.error(error?.response?.data?.error?.message);
      }
      const userInfoData = localStorage.getItem("user") as string;
      const userData = JSON.parse(userInfoData);
      const token = userData.user.accessToken;

      const url = `${process.env.REACT_APP_API_HOST}auth/refresh`;
      let status: number;
      fetch(url, {
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          status = response.status;
          return response.json();
        })
        .then((response) => {
          if (status === 200) {
            updateTokens(userData, response);
          } else {
            redirectToLogin();
          }
        })
        .catch((err) => redirectToLogin());
    }
    function updateTokens(userData: any, response: any) {
      /**
       * if the refreshToken has valid time it will send the
       * new tokens, update that tokens to current user data
       */
      saveCookie(ACCESS_TOKEN, response.data.user.accessToken);
      userData.user = response?.data?.user;
      localStorage.setItem("user", JSON.stringify(userData));
      /**
       * once new tokens are updated refresh the current route
       */
     window.location.reload();
    }

    function redirectToLogin() {
      /**
       * Refresh token session time also expired, so clear the local storage & cookies
       * then redirect the user to login page
       */
      removeCookie(ACCESS_TOKEN);
      localStorage.removeItem("user");
      window.location.href = "/login";
    }

    if (error.response && error.response.status === 429) {
      throw new ServerException({
        message: error?.response?.data?.error?.message,
      });
    }

    if (error.response && error.response.status === 500) {
      throw new ServerException({
        message: error?.response?.data?.error?.message,
      });
    }

    if (error.response && error.response.status === 404){
      throw new AuthenticationException(error?.response?.data?.error?.message);
    }

    if (error.response && error.response.status === 422) {
      throw new ServerException({
        message: error?.response?.data?.error?.message,
      });
    }

    throw new ServerException({
      message: error?.response?.data?.error?.message,
    });
  }
};
