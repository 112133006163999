import React, {createContext,FC,useReducer} from 'react';
import { SidebarReducer } from './SidebarReducer';

//initial value types
type initialValuesType={
    key?:string
}
//initial values
const initialValues ={
    key:'2'
}

//context
export const SidebarContext = createContext<{state:initialValuesType, dispatch:React.Dispatch<any>}>({state:initialValues,dispatch:()=>null});

export const SidebarContextProvider:FC=({children})=>{
//use reducer
    const [state, dispatch] = useReducer(SidebarReducer, initialValues);

    return(
        <SidebarContext.Provider value={{state,dispatch}}>
            {children}
        </SidebarContext.Provider>
    )
}
