import { FC } from "react";
import { Input,Form } from "formik-antd";
import './index.css';



export type TextAreaProps = {
 rows?:number;
 name?:any;
 placeholder?:string;
};
const {TextArea} = Input;
export const FormTextArea: FC<TextAreaProps> = ({rows=2,name,placeholder}) => <Form.Item name={name}><TextArea name={name} rows={rows} maxLength={1000} placeholder={placeholder}/></Form.Item>
