import { FC,useCallback } from 'react';
import * as yup from 'yup';
import { useHistory } from 'react-router'; 
import { Box, Button, Text,Grid } from 'atoms';
import { Form, Formik } from 'formik';
import { FormInput } from 'molecules/FormInput';
import Logo from "assets/loginicon/app_icon.png";
import useAuth from '../context/Authentication';
import { useFormSubmitWithLoading } from 'hooks/useFormSubmitWithLoading';
import {base64} from "utils/helpers";


const ForgotPasswordValidationSchema = yup.object().shape({
  user: yup.string().email('Invalid Email').required('Email is Required'),
});

export type ForgotPasswordProps = {};



export const ForgotPassword: FC<ForgotPasswordProps> = () => {
  const history = useHistory();
  const {
    actions: { forgotPassword },
  } = useAuth();
  
  const onSubmit = useCallback(
    (values) => {
      let valueCopy={Email:values?.user}
      forgotPassword(valueCopy,()=> {history.push(`/otp?code=${base64.encode(JSON.stringify({user:values.user,type:"Forget_Pass"}))}`)});
    },
    [forgotPassword,history]
  );
  const { onSubmitHandler, loading } = useFormSubmitWithLoading(onSubmit);
  return (
    <Grid border="1px solid #ededed" justifyContent="center" padding="40px" width="488px">
    <Box>
    <Box textAlign="center" mx="auto" width="150px">
          <img src={Logo} width="100%" alt="logo"/>
        </Box>
      <Text
        as="h1"
        fontSize="1.6rem"
        color="bluegray.700"
        textAlign="center"
        lineHeight="2.6rem"
        mb="1.25rem"
      >
       Forgot Password?
      </Text>
      <Text fontSize="0.875rem" color="rgba(0, 0, 0, 0.45)" textAlign="center">
      Enter the email address you used when you joined and we’ll send you instructions to reset your password.
        </Text>
      <Formik
        initialValues={{ user: '' }}
        onSubmit={onSubmitHandler}
        validationSchema={ForgotPasswordValidationSchema}
      >
        {() => (
          <Form>
            <Box mt="1.875rem" width="20rem" mx="auto">
              <FormInput label="Email address" name="user" labelTop={false} iconType="mail"/>
              <Button width="100%" mt="1.2rem" height="40px" fontSize="1rem" loading={loading}>
                Send Code
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
    </Grid>
  );
};
