type ActionType ={
    type:string,
    payload:object
}


export const SidebarReducer=(state:any,action:ActionType)=>{
	if (action.type === 'SET_SIDEBAR') {
		return {...state,key: action.payload}
	} 
	else {
		return state;
	}
}

