import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Loader } from 'atoms';
import { AuthContainer } from 'pages/Auth/AuthContainer/AuthContainer';
import Page404 from 'pages/Auth/Page404';

const AdminDashboard = React.lazy(
  () => import('pages/Admin'),
);

export const UnauthenticatedRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path="/" component={AuthContainer} />
        <Route path="/page404" component={Page404} />
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
};

export const AuthenticatedRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path="/" component={AdminDashboard} />
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
};
