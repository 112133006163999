export interface UserIconProps {
  color: string;
}

export const UserIcon = (props: UserIconProps) => (
  <svg
    width="14"
    height="16"
    viewBox="0 -1 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.4">
      <path
        d="M14.189 12.4938C13.8521 11.6959 13.3633 10.9711 12.7497 10.3599C12.138 9.74685 11.4133 9.25809 10.6158 8.92059C10.6086 8.91702 10.6015 8.91523 10.5943 8.91166C11.7068 8.10809 12.4301 6.79916 12.4301 5.32237C12.4301 2.87594 10.4479 0.893799 8.00149 0.893799C5.55506 0.893799 3.57291 2.87594 3.57291 5.32237C3.57291 6.79916 4.29613 8.10809 5.40863 8.91344C5.40148 8.91702 5.39434 8.9188 5.3872 8.92237C4.5872 9.25987 3.86934 9.7438 3.25327 10.3617C2.64025 10.9734 2.15149 11.698 1.81399 12.4956C1.48242 13.2764 1.3036 14.1135 1.2872 14.9617C1.28672 14.9807 1.29007 14.9997 1.29703 15.0174C1.304 15.0352 1.31444 15.0513 1.32776 15.065C1.34107 15.0786 1.35698 15.0895 1.37455 15.0969C1.39212 15.1043 1.41099 15.1081 1.43006 15.1081H2.50149C2.58006 15.1081 2.64256 15.0456 2.64434 14.9688C2.68006 13.5902 3.23363 12.2992 4.2122 11.3206C5.2247 10.3081 6.56934 9.75094 8.00149 9.75094C9.43363 9.75094 10.7783 10.3081 11.7908 11.3206C12.7693 12.2992 13.3229 13.5902 13.3586 14.9688C13.3604 15.0474 13.4229 15.1081 13.5015 15.1081H14.5729C14.592 15.1081 14.6109 15.1043 14.6284 15.0969C14.646 15.0895 14.6619 15.0786 14.6752 15.065C14.6885 15.0513 14.699 15.0352 14.7059 15.0174C14.7129 14.9997 14.7162 14.9807 14.7158 14.9617C14.6979 14.1081 14.5211 13.2777 14.189 12.4938ZM8.00149 8.3938C7.18184 8.3938 6.41041 8.07416 5.83006 7.4938C5.2497 6.91344 4.93006 6.14201 4.93006 5.32237C4.93006 4.50273 5.2497 3.7313 5.83006 3.15094C6.41041 2.57058 7.18184 2.25094 8.00149 2.25094C8.82113 2.25094 9.59256 2.57058 10.1729 3.15094C10.7533 3.7313 11.0729 4.50273 11.0729 5.32237C11.0729 6.14201 10.7533 6.91344 10.1729 7.4938C9.59256 8.07416 8.82113 8.3938 8.00149 8.3938Z"
        fill={props.color}
      />
    </g>
  </svg>
);
