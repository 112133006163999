import { Text } from 'atoms/Text';

interface WarningProps {
  text: string;
}
export const Warning = ({ text }: WarningProps) => {
  return (
    <Text
      as="h3"
      variant="small"
      color="steelgrey.500"
      position="absolute"
      bottom={{ xs: -18 }}
      left="12px"
    >
      {text}
    </Text>
  );
};
