import { FC,useCallback } from 'react';
import * as yup from 'yup';
import { useHistory } from 'react-router';
import { Box, Button, Text,Grid } from 'atoms';
import { Form, Formik } from 'formik';
import { FormInput } from 'molecules/FormInput';
import useAuth from '../context/Authentication';
import { useLocation } from 'react-router';
import { useFormSubmitWithLoading } from 'hooks/useFormSubmitWithLoading';
import Logo from "assets/loginicon/app_icon.png";

const MIN = 8;

const ResetPasswordValidationSchema = yup.object().shape({
  password: yup.string().required('Password is Required')
  .min(MIN, `Minimum ${MIN} characters needed`)
  .matches(/^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,})$/,
    'Must Contain One Uppercase, One Lowercase, One Number'
  ),
  confirm_password: yup
    .string()
    .required('Confirm Password is Required')
    .oneOf([yup.ref('password'), 'password'], "Password doesn't match"),
});

export type ResetPasswordProps = {};

export const ResetPassword: FC<ResetPasswordProps> = () => {
  const {state}:any = useLocation();

 
  let data = state?.data;
  const history = useHistory();
  const {
    actions: { resetPassword },
  } = useAuth();
  
  const onSubmit = useCallback(
    (values) => {
      const valuesCopy = {email: data, NewPassword: values?.password};
      resetPassword(valuesCopy,()=> {history.push(`/login`)});
    },// eslint-disable-next-line react-hooks/exhaustive-deps
    [resetPassword,history]
  );
  const { onSubmitHandler, loading } = useFormSubmitWithLoading(onSubmit);

  return (
    <Grid border="1px solid #ededed" justifyContent="center" padding="40px" width="488px">
    <Box>
    <Box textAlign="center" mx="auto" width="150px">
          <img src={Logo} width="100%" alt="logo"/>
        </Box>
      <Text
        as="h1"
        fontSize="1.6rem"
        color="bluegray.700"
        my="0.7rem"
        textAlign="center"
        lineHeight="2.6rem"
      >
        Reset Password
      </Text>
      <Formik
        initialValues={{ password: '', confirm_password: '' }}
        onSubmit={onSubmitHandler}
        validationSchema={ResetPasswordValidationSchema}
      >
        {() => (
          <Form>
            <Box mt="1rem" width="20rem" mx="auto">
              <FormInput label="New Password" name="password" type="password" labelTop={false} iconType="lock"/>
              <FormInput label="Confirm Password" name="confirm_password" type="password" labelTop={false} iconType="lock"/>
              <Button width="100%" mt="0.4rem" height="40px" fontSize="1rem" loading={loading}>
                Update
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
    </Grid>
  );
};
