import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

#root {
  min-width: 100%;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
}

a:hover{
  color:#000000;
}
`;

export default GlobalStyle;
