import { FC } from 'react';
import { useField } from 'formik';
import { InputField, InputFieldProps } from 'atoms/InputField';

export interface FormInputProps extends InputFieldProps {
  name: string;
  labelTop:boolean;
  iconType:string;
  borderRequired?:boolean;
}

export const FormInput: FC<FormInputProps> = ({ name,labelTop,iconType,borderRequired,...props}) => {
  const [field, meta] = useField(name);
  const error = (meta.touched && meta.error) || '';
  return <InputField {...field} {...props} error={error} labelTop={labelTop} iconType={iconType} borderRequired={borderRequired}/>;
};
