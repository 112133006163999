import { FC, useCallback } from "react";
import { Box, Text, Grid } from "atoms";
import Logo from "assets/loginicon/app_icon.png";
import LoginForm from "templates/LoginForm";
import useAuth from "../context/Authentication";
import { useHistory } from 'react-router'; 
import {base64} from "utils/helpers";
import { toast } from 'react-toastify';



export type AdminLoginProps = {};

export const AdminLogin: FC<AdminLoginProps> = () => {
  let history = useHistory();
  const {
    actions: { login },
  } = useAuth();

  const onLogin = useCallback(
    (values) => {
      // login(values,()=> {history.push(`/otp?code=${base64.encode(JSON.stringify({user:values.user,type:"FirstLogin"}))}`)});
      login(values).then(res=>{
        console.log(res);
        if(res.forcePasswordReset === true && res?.userDetails?.isVerified === true){
          toast.error("Please change the password.");
          history.push({pathname: '/reset-password',
        state: {
          data: values.user,
        }},);
        }else if(res?.forcePasswordReset === false && res?.userDetails?.isVerified === true) {
          console.log('');
        }else if(res?.status === "Failed"){
          toast.error(res?.message);
        }
        else{
          toast.error("Please change the password with the OTP received on Registered Email.");
          history.push(`/otp?code=${base64.encode(JSON.stringify({user:values.user,type:"FirstLogin"}))}`);
        }
      })
    },// eslint-disable-next-line react-hooks/exhaustive-deps
    [login]
  );
  return (
    <Grid
      border="1px solid #ededed"
      justifyContent="center"
      padding="40px"
      width="440px"
    >
      <Box>
        <Box textAlign="center" mx="auto" width="150px">
          <img src={Logo} width="100%" alt="logo"/>
        </Box>
        <Text
          fontWeight="500"
          fontSize="14px"
          lineHeight="22px"
          width="35px"
          borderBottom="2px solid #333333"
        >
          Login
        </Text>
        <LoginForm onSubmit={onLogin} />
      </Box>
    </Grid>
  );
};
