import moment from 'moment';

export const base64 = {
    decode: (s: string) => window.atob(s),
    encode: (b: string) => window.btoa(b)
};

export const toJSON = (jsonString: string): object | null => {
    try {
        return JSON.parse(jsonString)
    } catch (e) {
        console.log(e)
        return null
    }
}

export const getExtension = (fileName: string) => fileName.split('.').pop()

export function timeStamp() {
	return moment().format('dddd, MMMM Do YYYY, h:mm:ss a');
}
//sorting columns in table
export const getSorter = (sorter:any) => {
    console.log(sorter);
	return `${sorter?.['columnKey']}|${
		sorter?.['order'] === 'descend' ? 'desc' : 'asc'
	}`;
};

export const randomString = (length: number) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}


export const returnTODOStatus = (currentPortal: string, currentTodo: any, isCompleted: boolean): number => {
    if (isCompleted) {
        return 3;
    } else if (currentPortal === "coach") {
        if (currentTodo.status === 3 && !isCompleted) {
            return 1
        }
        return currentTodo.status;
    }
    return 1
}



export const returnEditorVisible = (currentPortal: string, currentTodo: any, userId: string): boolean => {
    if (currentPortal === "client") {
        return true;
    }
    if (currentTodo.assignedTo === userId) {
        return true;
    }
    if (currentPortal === "coach" && currentTodo.status !== 1) {
        return true;
    }
    return false
}



export const Linkify = (inputText: string) => {
    // //URLs starting with http://, https://, or ftp://
    // var replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    // var replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

    // //URLs starting with www. (without // before it, or it'd re-link the ones done above)
    // var replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    // var replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

    // //Change email addresses to mailto:: links
    // var replacePattern3 = /(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,6})/gim;
    // var replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    // return replacedText
    return inputText;
}