import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { system } from 'styled-system';

import { Box, BoxProps } from 'atoms/Box';
import ArrowUp from 'assets/icons/ArrowUp';
import ArrowDown from 'assets/icons/ArrowDown';
import HomeIcon from 'assets/icons/HomeIcon';
import DeleteIcon from 'assets/icons/DeleteIcon';
import EyeOpenIcon from 'assets/icons/EyeOpenIcon';
import EyeCloseIcon from 'assets/icons/EyeCloseIcon';
import LogoutIcon from 'assets/icons/LogoutIcon';
import { ProfileIcon } from 'assets/icons/ProfileIcon';
import SettingsIcon from 'assets/icons/SettingsIcon';
import UsersIcon from 'assets/icons/UsersIcon';
import FileIcon from 'assets/icons/FileIcon';
import ListIcon from 'assets/icons/ListIcon';
import GlobeIcon from 'assets/icons/GlobeIcon';
import AdminDashboardIcon from 'assets/icons/AdminDashboardIcon';
import MailIcon from 'assets/icons/MailIcon';
import NotificationsIcon from 'assets/icons/NotificationsIcon';
import PhoneIcon from 'assets/icons/Phone';
import EmailIcon from 'assets/icons/Email';
import ChevronLeft from 'assets/icons/ChevronLeft';
import ChevronRight from 'assets/icons/ChevronRight';

interface IconWrapperProps extends BoxProps {
  color?: string;
}

export const IconWrapper = styled(Box)<IconWrapperProps>`
  display: grid;
  & > svg {
    height: 100%;
    width: 100%;
    ${system({
      fill: {
        property: 'fill',
        scale: 'colors',
      },
    })}
  }
`;

export const icons = {
  chevronRight: ChevronRight,
  chevronLeft: ChevronLeft,
  profile: ProfileIcon,
  logout: LogoutIcon,
  settings: SettingsIcon,
  usersIcon: UsersIcon,
  fileIcon: FileIcon,
  listIcon: ListIcon,
  globeIcon: GlobeIcon,
  adminDashboardIcon: AdminDashboardIcon,
  mailIcon: MailIcon,
  home: HomeIcon,
  notificationsIcon: NotificationsIcon,
  eyeOpenIcon: EyeOpenIcon,
  deleteIcon: DeleteIcon,
  eyeCloseIcon: EyeCloseIcon,
  arrowUp: ArrowUp,
  arrowDown: ArrowDown,
  email: EmailIcon,
  phone: PhoneIcon,
};

export interface IconProps extends IconWrapperProps {
  name: keyof typeof icons;
  fill?: string;
  onClick?: () => void;
}

export const Icon: FC<IconProps> = ({ name, height, width, ...props }: IconProps) => {
  const IconSVG: any = useMemo(() => icons[name], [name]) as any;

  return (
    <IconWrapper height={height} width={width} {...props}>
      <IconSVG key={name} />
    </IconWrapper>
  );
};
