import { InputFieldBase, Text } from "atoms";
import ReactPhoneInput,{PhoneInputProps as ReactPhoneInputProps} from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Error } from "atoms/Error";
import { Warning } from "atoms/Warning";
import styled from "styled-components";
import "./phonenumber.css";
const StyledPhoneInput = styled(ReactPhoneInput)``;
export interface PhoneInputProps extends ReactPhoneInputProps {
  label?: string;
  error?: string;
  warning?: string;
}

export const PhoneInput: React.FC<PhoneInputProps> = ({
  label,
  error,
  warning,
  ...props
}: PhoneInputProps) => {
  return (
    <InputFieldBase>
      <Text
        textTransform="uppercase"
        as="label"
        fontSize="h3"
        color="primary.50"
        fontFamily="medium"
      >
        {label}
      </Text>
      <StyledPhoneInput
        country={'us'}
        containerClass={`phone-number ${error && "error"}`}
        inputClass={`phone-input ${error && "error"}`}
        {...props}
        containerStyle={{ marginBottom: "30px" }}
      />
      {error && <Error text={error} />}
      {warning && <Warning text={warning} />}
      <span className="dial-code"></span>
    </InputFieldBase>
  );
};
