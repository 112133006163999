import { FC } from 'react';
import css from '@styled-system/css';
import styled from 'styled-components';
import { NavLink as Link, NavLinkProps } from 'react-router-dom';

const NavLink = styled(Link)<NavLinkProps>(
  css({
    textDecoration: 'none',
    fontSize:'0.875rem',
    lineHeight:'24px',
    width:"157px",
    color: 'orange',
    '&.active': {
      color: 'primary.500',
    },
  }),
);

export type StyledNavLinkProps = {} & NavLinkProps;

export const StyledNavLink: FC<StyledNavLinkProps> = ({ children, ...rest }) => {
  return <NavLink {...rest}>{children}</NavLink>;
};
