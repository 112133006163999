import { FC } from 'react';
import styled from 'styled-components';
import {
  layout,
  color,
  space,
  variant,
  typography,
  fontSize,
  border,
  ColorProps,
  LayoutProps,
  SpaceProps,
  TypographyProps,
  FontSizeProps,
} from 'styled-system';

import { Box, BoxProps } from 'atoms/Box';
import css from '@styled-system/css';

import { Flex } from '../Flex';
import { Text } from '../Text';
import Loader from '../Loader';

type Size = 'l' | 'md' | 'sm' | 'xs';

const variants = {
  primary: {
    color: 'white',
    backgroundColor: 'primary.50',
    transition: 'all 0.3s',
    border: 0,
    letterSpacing: 0.4,
    h3: {
      textTransform: 'uppercase',
    },
    '&:hover': {
      transform: 'scale(1.004)',
      backgroundColor: 'primary.400',
    },
    '&:focus': {
      outline: 0,
    },
  },
  secondary: {
    color: 'primary.0',
    backgroundColor: 'white',
    borderRadius: 0,
    border: '1px solid',
    borderColor: 'primary.0',
    transition: 'all 0.3s',
    h3: {
      textTransform: 'uppercase',
    },
    '&:hover': {
      transform: 'scale(1.004)',
      color: 'white.0'
    },
  },
  tertiary: {
    color: 'white.0',
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: 'primary.500',
    transition: 'all 0.3s',
    borderRadius: 4,
    h3: {
      textTransform: 'uppercase',
    },
    '&:hover': {
      transform: 'scale(1.004)',
      color: 'black.0',
      backgroundColor: 'white.0',
    },
  },
  danger: {
    color: 'error.0',
    px: 6,
    py: 2,
    transition: 'all 0.3s',
    backgroundColor: 'white.0',
    border: '1px solid',
    borderColor: 'red.0',
    borderRadius: 4,
    '&:hover': {
      transform: 'scale(1.004)',
      color: 'white.0',
      backgroundColor: 'error.0',
      borderColor: 'error.0',
      boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
    },
  },
  transparent: {
    color: 'grey.1',
    px: 6,
    py: 3,
    transition: 'all 0.3s',
    border: 0,
    backgroundColor: 'transparent',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: 'grey.6',
    },
  },
};

type Variant = keyof typeof variants;

interface ContainerProps
  extends ColorProps,
    LayoutProps,
    SpaceProps,
    TypographyProps,
    BoxProps,
    FontSizeProps {
  color?: string;
  size?: Size;
  variant?: Variant;
  type?: 'button' | 'submit' | 'reset';
  onClick?(e?: any): any | void;
  whiteSpace?: any;
  disabled?: boolean;
  id?: string;
  value?: string;
}

export const Container = styled(Box)<ContainerProps>`
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  ${variant({
    variants,
  })}
  ${({ disabled }) =>
    disabled &&
    css({
      color: 'gray.200',
      boxShadow: 'none',
      cursor: 'not-allowed',
    })}
  ${({ whiteSpace }) =>
    whiteSpace &&
    css({
      whiteSpace,
    })}
  ${layout}
  ${color}
  ${typography}
  ${space}
  ${fontSize}
  ${border}
`;

export interface ButtonProps extends ContainerProps {
  title?: string;
  loading?: boolean;
  disabled?: boolean;
  'data-testid'?: string;
}

export const Button: FC<ButtonProps> = ({
  disabled,
  loading,
  children,
  variant: v = 'primary',
  ...rest
}) => {
  return (
    <Container variant={v} as="button" {...rest} disabled={disabled || loading} overflow="hidden">
      {loading && (
        <Flex
          left={0}
          right={0}
          position="absolute"
          justifyContent="center"
          alignItems="center"
          fontSize={2}
          height="15px"
          width="15px"
          mx="auto"
        >
          <Loader loading={loading} />
        </Flex>
      )}
      <Text opacity={loading ? 0 : 1}>{children}</Text>
    </Container>
  );
};
