import {
	DatePicker as AntDatePicker,
	DatePickerProps as AntDatePickerProps,
	TimeRangePickerProps,
} from 'antd';
import 'antd/dist/antd.css';
import { Box } from 'atoms/Box';
import { Merge } from 'typings/utils';


const { RangePicker } = AntDatePicker;
export interface DateProps extends TimeRangePickerProps {
	onChangeDate?: (date: any, dateStrings: any) => void;
	isRangePicker?: boolean;
	style?: object;
}

export type DatePickerProps = Merge<DateProps, AntDatePickerProps>;

export const DatePicker = ({
	onChangeDate,
	isRangePicker = false,
	style,
	allowClear,
	...props
}: DatePickerProps) => {
	const Range = () => {
		
		return (
			<RangePicker
				className="ehuddle-date-picker-style"
				format={'MMM DD YYYY'}
				onChange={(date: any, dateStrings: any) =>
					onChangeDate && onChangeDate(date, dateStrings)
				}
				style={style}
				defaultValue={props.defaultValue as any}
				allowClear={allowClear}
				// open={open}
				value={props.value as any}
			/>
		);
	};

	const DateP = () => {
		return (
			<AntDatePicker
				format={'MMM DD YYYY'}
				className="ehuddle-date-picker-style"
				onChange={(date: any, dateStrings: any) =>
					onChangeDate && onChangeDate(date, dateStrings)
				}
				style={style}
				defaultValue={props.defaultValue}
				allowClear={allowClear}
				// open={open}
				value={props.value}
			/>
		);
	};
	return <Box>{isRangePicker ? Range() : DateP()}</Box>;
};
